import React from 'react'
import Seo from "../../../src/components/seo/index";
import Layout from "./layout/index";
import AdminDataTable from './components/adminDataTable'

const Datatable = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
     <AdminDataTable />
    </Layout>
  </div>
  )
}

export default Datatable